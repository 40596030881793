import { useIntl } from 'react-intl';

const convertCountryCode = (country: string): string => {
  const intl = useIntl();
  switch (country) {
    case 'FR': {
      return intl.formatMessage({
        id: 'france',
        defaultMessage: 'France',
      });
    }
    case 'DE': {
      return intl.formatMessage({
        id: 'germany',
        defaultMessage: 'Germany',
      });
    }
    case 'GR': {
      return intl.formatMessage({
        id: 'greece',
        defaultMessage: 'Greece',
      });
    }
    case 'IE': {
      return intl.formatMessage({
        id: 'ireland',
        defaultMessage: 'Ireland',
      });
    }
    case 'DK': {
      return intl.formatMessage({
        id: 'denmark',
        defaultMessage: 'Denmark',
      });
    }
    case 'CH': {
      return intl.formatMessage({
        id: 'switzerland',
        defaultMessage: 'Switzerland',
      });
    }
    case 'FI': {
      return intl.formatMessage({
        id: 'finland',
        defaultMessage: 'Finland',
      });
    }
    case 'GB': {
      return intl.formatMessage({
        id: 'uk',
        defaultMessage: 'United Kingdom',
      });
    }
    case 'CZ': {
      return intl.formatMessage({
        id: 'czechia',
        defaultMessage: 'Czech',
      });
    }
    case 'SK': {
      return intl.formatMessage({
        id: 'slovakia',
        defaultMessage: 'Slovakia',
      });
    }
    case 'PT': {
      return intl.formatMessage({
        id: 'portugal',
        defaultMessage: 'Portugal',
      });
    }
    case 'HU': {
      return intl.formatMessage({
        id: 'hungary',
        defaultMessage: 'Hungary',
      });
    }
    case 'HR': {
      return intl.formatMessage({
        id: 'croatia',
        defaultMessage: 'Croatia',
      });
    }
    case 'BG': {
      return intl.formatMessage({
        id: 'bulgaria',
        defaultMessage: 'Bulgaria',
      });
    }
    default: {
      return intl.formatMessage({
        id: 'uk',
        defaultMessage: 'United Kingdom',
      });
    }
  }
};

export default convertCountryCode;
