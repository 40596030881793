// @ts-nocheck
/* eslint-disable */
import React, { useEffect } from 'react';

import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  FormItemProps,
  Row,
  Typography,
} from 'antd';

type AntFormItemProps = Omit<FormItemProps, 'name'>;
interface Field<Data> extends AntFormItemProps {
  span?: number;
  name: keyof Data;

  render(props: { disabled: boolean }): JSX.Element;
}

export interface FormTemplateProps<Data> {
  sections: {
    title?: React.ReactNode;
    description?: React.ReactNode;
    fields: Field<Data>[][];
  }[];
  submitText?: React.ReactNode;
  cancelText?: React.ReactNode;
  onClose?: () => void;
  loading?: boolean;
  saving: boolean;
  initialValues: Data;
  skipText?: React.ReactNode;
  formInstance?: FormInstance<Data>;

  onSubmit(data: Data): void;

  onSkip?(): void;
}

const { useForm } = Form;
export { useForm };

/**
 *
 * @param {FormTemplateProps} props - @see {@link FormTemplateProps}
 * @param {function} props.onClose - function called when 'cancel' button is clicked
 * @param {function} props.onSkip - function called when 'skip' button is clicked
 * @param {function} props.onSubmit - function called when 'submit' button is clicked
 * @param {boolean} props.loading - boolean indicating loading status of form data
 * @param {boolean} props.saving - boolean indicating saving status of form data
 * @param {object} props.initialValues - object containing initial values to display in form
 * @param {Array<object>} props.sections - array of objects {title, description, fields} where fields is an array of {@link Field}[]
 * @param {React.ReactNode} props.submitText - ReactNode for rendering text for submit button
 * @param {React.ReactNode} props.cancelText - ReactNode for rendering text for cancel button
 * @param {React.ReactNode} props.skipText - ReactNode for rendering text for skip button
 * @param {FormInstance} props.formInstance - AntD form instance (@see https://ant.design/components/form) to provide external control of form fields. If not provided, a form instance will be created internally
 *
 * @description A form component which combines AntD form and input elements to create a sectionable, reusable, form template. It will accept any AntD input elements which are also accepted by the AntD Form component, and internally deals with values state. When the submit button is pressed, the provided onSubmit function will be called with the values collected by the form. All text options accept ReactNode as their type, allowing for wrapper components (e.g. enabling i18n).

The form is sectionable, and the number of items on a row is configurable via the sections props, and a 24 column grid system. The sections prop accepts a structure of nested arrays and objects to determine the layout of the form. Sections will be separated with a divider, and all sections will have their values submitted together when the submit button is pressed.

The internal state of the form can be read and manipulated if an AntD FormInstance is provided, via the formInstance prop. If not, the form will clear all internal state on close, and submit all internal state as the argument of the provided 'onSubmit' function.
 */
export const FormTemplate = <Data,>({
  onClose,
  onSkip,
  onSubmit,
  loading,
  saving,
  initialValues,
  sections,
  submitText,
  cancelText,
  skipText,
  formInstance,
}: FormTemplateProps<Data>) => {
  const [form] = Form.useForm<Data>();
  if (!formInstance) formInstance = form;

  const tailLayout = {
    wrapperCol: { span: 22 },
  };

  useEffect(() => {
    if (initialValues) {
      // cast initialValues as any because Data !== RecursivePartial<Data> for some reason
      formInstance.setFieldsValue(initialValues as any);
    }
  }, [initialValues]);

  const handleClose = () => {
    formInstance.resetFields();
    onClose();
  };

  const handleSubmit = async (data: Data) => {
    await onSubmit(data);
  };

  return (
    <Form
      name="basic"
      onFinish={handleSubmit}
      onFinishFailed={() => {}}
      form={formInstance}
      style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
      initialValues={initialValues}
      layout="vertical"
    >
      {sections.map((section, i) => (
        <div key={i}>
          {section.title && (
            <Typography.Title level={4}>{section.title}</Typography.Title>
          )}
          {section.description && (
            <>
              <Typography.Text>{section.description}</Typography.Text>
              <div style={{ marginBottom: '16px' }} />
            </>
          )}
          {section.fields.map((row, i) => (
            <Row key={i} gutter={row.length > 0 ? 16 : 0}>
              {row.map((item, i) => (
                <Col key={i} span={item.span ? item.span : 24 / row.length}>
                  <Form.Item
                    label={item.label}
                    name={`${item.name}`}
                    rules={item.rules}
                  >
                    {item.render({ disabled: loading || saving })}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          ))}
          {i !== sections.length - 1 && <Divider />}
        </div>
      ))}

      <div style={{ flex: 1 }} />

      <Form.Item {...tailLayout}>
        <Row justify="end">
          {onClose && (
            <Button
              type="ghost"
              onClick={handleClose}
              style={{ marginRight: 15 }}
              disabled={loading || saving}
            >
              {cancelText || 'Cancel'}
            </Button>
          )}
          {onSkip && (
            <Button
              type="ghost"
              onClick={onSkip}
              style={{ marginRight: 15 }}
              disabled={loading || saving}
            >
              {skipText || 'Skip'}
            </Button>
          )}
          <Button
            type="primary"
            htmlType="submit"
            loading={saving}
            disabled={loading}
          >
            {submitText || 'Submit'}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
