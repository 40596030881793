/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { Input, Select, Typography } from 'antd';
import styled from 'styled-components';

import { FormattedMessage, useIntl } from 'react-intl';
import { Details } from '../../Context';
import { FormTemplate } from '../../../../../components/Old/form-template';

const { Title } = Typography;
const { Option } = Select;
const Content = styled.div`
  width: 99%;
  padding-top: 20px;
`;

export interface FormData {
  line1: string;
  line2: string;
  city: string;
  county: string;
  country: string;
  postCode: string;
  vatId: string;
}

interface Props {
  details: Details | undefined;
  loading: boolean;
  saving: boolean;
  demo: boolean;

  handleSubmit(data: FormData): void;
}

const BusinessDetails = ({
  handleSubmit,
  details,
  loading,
  demo,
  saving,
}: Props): JSX.Element => {
  const intl = useIntl();
  const titleRef = useRef(null);

  useEffect(() => {
    const scroll =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore ???
      titleRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Content ref={titleRef}>
      <Title level={4}>
        <FormattedMessage
          id="enterVatId"
          defaultMessage="Please enter your vatId"
        />
      </Title>
      <FormTemplate<FormData>
        sections={[
          {
            fields: [
              [
                {
                  label: (
                    <FormattedMessage
                      id="vatNumber"
                      defaultMessage="VAT Number"
                    />
                  ),
                  name: 'vatId',
                  render: ({ disabled }) => <Input disabled={disabled} />,
                  rules: [
                    {
                      required: !demo,
                      message: intl.formatMessage({
                        id: 'pleaseComplete',
                        defaultMessage: 'Please complete this field.',
                      }),
                    },
                  ],
                  span: 10,
                },
              ],
            ],
          },
        ]}
        initialValues={
          details
            ? {
                city: details.billingAddress ? details.billingAddress.city : '',
                country: details.billingAddress
                  ? details.billingAddress.country
                  : '',
                county: details.billingAddress?.county
                  ? details.billingAddress.county
                  : '',
                postCode: details.billingAddress
                  ? details.billingAddress.postCode
                  : '',
                line1: details.billingAddress
                  ? details.billingAddress.line1
                  : '',
                line2: details.billingAddress?.line2
                  ? details.billingAddress.line2
                  : '',
                vatId: details.vatId ? details.vatId.value : '',
              }
            : {
                city: '',
                country: 'GB',
                county: '',
                postCode: '',
                line1: '',
                line2: '',
                vatId: '',
              }
        }
        onSubmit={handleSubmit}
        saving={saving}
        loading={loading}
      />
    </Content>
  );
};
export default BusinessDetails;
